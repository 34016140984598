import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import OneOffFooter from "../components/oneOff/oneOffFooter"
import OneOffHeader from "../components/oneOff/oneOffHeader"
import OneOffItem from "../components/oneOff/oneOffItem"

import "../styles/page-oneOff.scss"

const OneOff = ({ data }) => {
  const page = data.wpOneOff

  return (
    <Layout id="one-off" darkMode={page.Header.darkMode}>
      <Seo
        title={page.title}
        seoTitle={page.SeoMeta.seoTitle}
        description={page.SeoMeta.seoDescription}
      />

      <OneOffHeader h1={page.title} header={page.Header} />

      <div className="container">
        <ul style={{margin: "0 0 80px 0", padding: 0, listStyle: "none"}}>
          {page.OneOffData.content.map(content => <OneOffItem {...content} key={content.title} />)}
        </ul>
      </div>

      <OneOffFooter/>
    </Layout>
  )
}

export default OneOff

export const query = graphql`query ($slug: String) {
  wpOneOff(slug: {eq: $slug}) {
    title
    Header {
      darkMode
      headerImage {
        altText
        title
        localFile {
          childImageSharp {
            gatsbyImageData(height: 640, layout: FULL_WIDTH)
          }
        }
      }
    }
    OneOffData {
      content {
        title
        copy
        typeformEmbed
        pdf {
          localFile {
            url
          }
        }
        image {
          altText
          title
          localFile {
            childImageSharp {
              gatsbyImageData(height: 320, layout: FULL_WIDTH)
            }
          }
        }
      }
    }
    SeoMeta {
      seoTitle
      seoDescription
    }
  }
}
`
