import React from "react"
import PropTypes from 'prop-types'
import { GatsbyImage } from "gatsby-plugin-image";
import OneOffEmbed from "./oneOffEmbed"

import * as style from './oneOff.module.scss'


const OneOffItem = ({ title, copy, image, pdf, typeformEmbed }) => {
  return (
    <li className={style.oneOffItem}>
      { pdf && (
      <a href={pdf.localFile.url} className={style.imageCol} target="_blank" rel="noreferrer">
        <GatsbyImage
          image={image.localFile.childImageSharp.gatsbyImageData}
          alt={image.altText}
          title={image.title} />
      </a>
      )}
      <div className={style.contentCol}>
        <h2>{title}</h2>
        <div dangerouslySetInnerHTML={{ __html: copy }} />
        { typeformEmbed && (
          <OneOffEmbed url={typeformEmbed} />
        )}
        { pdf && (
        <div className="mh-button-wrap">
          <a href={pdf.localFile.url} className="mh-button" target="_blank" rel="noreferrer">Pobierz teraz</a>
        </div>
        )}
      </div>
    </li>
  );
}

OneOffItem.propTypes = {
  title: PropTypes.string.isRequired,
  copy: PropTypes.string.isRequired,
  image: PropTypes.object.isRequired,
  pdf: PropTypes.object,
  typeformEmbed: PropTypes.string
}

export default OneOffItem
