import React from "react"
import PropTypes from 'prop-types'
import * as typeformEmbed from '@typeform/embed'

class OneOffEmbed extends React.Component {
  constructor({ url }) {
    super()
    this.el = null
  }

  componentDidMount() {
    if (this.el) {
      typeformEmbed.makeWidget(this.el, this.props.url)
    }
  }

  render() {
    return (
      <div ref={(el) => this.el = el} className="typeform-widget" style={{width: '100%', height: '500px'}} />
    )
  }
}

OneOffEmbed.propTypes = {
    url: PropTypes.string.isRequired
}

export default OneOffEmbed
