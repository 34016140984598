import React from "react"
import PropTypes from 'prop-types'
import { GatsbyImage } from "gatsby-plugin-image";

import * as style from './oneOff.module.scss'

const OneOffHeader = ({ h1, header }) => {
  return (
    <div className={style.oneOffHeader}>
      <GatsbyImage
        image={header.headerImage.localFile.childImageSharp.gatsbyImageData}
        alt={header.headerImage.altText}
        title={header.headerImage.title}
        style={{ height: '60vw', maxHeight: '500px'}} />
      <div className={style.h1Wrap}>
        <h1 style={{color: header.darkMode ? "white" : ""}}>{h1}</h1>
      </div>
    </div>
  );
}


OneOffHeader.propTypes = {
  h1: PropTypes.string,
  header: PropTypes.object
}

export default OneOffHeader
