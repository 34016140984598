import React from "react"

const OneOffFooter = () => {
  return (
    <section className="page-dark">
      <div className="container">
        <div style={{paddingTop: '60px', textAlign: 'center'}}>
          <h4>W przypadku pytań prosimy o kontakt: --brak--</h4>
          <a href="mailto:--brak--">--brak--</a>
        </div>
      </div>
    </section>
  )
}

export default OneOffFooter
